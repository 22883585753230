.default-bg {
  background-image: url("../../assets/images/main-bg.png");
  background-color: black;
  background-size: cover;
}

.map-bg {
  background-image: url("../../assets/images/map-bg.jpg");
  background-color: black;
  background-size: cover;
}

.search-blur::before {
  background-color: rgba(80, 80, 80, 0.6);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}
.search-blur::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
}
