@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "BMWTypeNextTT";
    font-weight: 600;
    src:
      local("BMWTypeNextTT"),
      url(./assets/fonts/BMWTypeNextTT-Regular.woff) format("woff");
  }
  @font-face {
    font-family: "BMWTypeNextTT";
    font-weight: 700;
    src:
      local("BMWTypeNextTT"),
      url(./assets/fonts/BMWTypeNextTT-Bold.woff) format("woff");
  }
  @font-face {
    font-family: "BMWTypeNextTT";
    font-weight: 400;
    src:
      local("BMWTypeNextTT"),
      url(./assets/fonts/BMWTypeNextTT-Thin.woff) format("woff");
  }
  @font-face {
    font-family: "BMWTypeNextTT";
    font-weight: 300;
    src:
      local("BMWTypeNextTT"),
      url(./assets/fonts/BMWTypeNextTT-Light.woff) format("woff");
  }
}

@layer utilities {
  .scroll-snap-none {
    scroll-snap-type: none;
  }
  .scroll-snap-x {
    scroll-snap-type: x;
  }
  .scroll-snap-y {
    scroll-snap-type: y;
  }
  .scroll-snap-align-start {
    scroll-snap-align: start;
  }
}

@keyframes numberChange {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-number-change {
  animation: numberChange 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.svg-map-image {
  mask-image: linear-gradient(#000 95%, transparent 100%);
}

body,
html,
#root {
  height: 100%;
}

html {
  --scrollbarBG: white;
  --thumbBG: #8e8e8e;
}

#root > * {
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.scrollbar-styled::-webkit-scrollbar {
  width: 0.7em;
  margin-right: 10px;
}
.scrollbar-styled {
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scrollbar-width: thin;
  scrollbar-width: 1em;
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition:
    mask-position 0.3s,
    -webkit-mask-position 0.3s;
}
.scrollbar-styled:hover {
  -webkit-mask-position: left top;
}
.scrollbar-styled::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  width: 0.8em;
}
.scrollbar-styled::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  margin: 1em;
  border-radius: 6px;
  min-height: 30px;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  scrollbar-width: none;
  scrollbar-width: 0px;
}
.seperate-tbody::before {
  content: "";
  display: block;
  height: 18px;
}
.first-cut-row {
  border-top-width: 2px !important;
  border-top-color: #f2f2f2 !important;
  border-top-style: dashed !important;
}

.legend-sizes {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
}

.hover-star {
  fill: none;
  stroke: theme("colors.black_grey");
}
.hover-star:hover,
.hover-star-active {
  fill: theme("colors.blue");
  stroke: theme("colors.blue");
}
.partner-link img {
  max-height: 210px;
}

.countdown-bg {
  background-image: url("./assets/images/countdown-bg.jpg");
}
.player-bg {
  background-image: url("./assets/images/thumb_stream.jpg");
}
